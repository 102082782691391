import "./CoverDescription.scss";
import React from "react"; 



export const CoverDescription = () =>{
    return(
        <div id="coverDescription">
            <h1>The best commercial cleaning services in Miami</h1>
            <h2>We focus on the cleaning of kitchens, restaurants and retail stores.</h2>
        </div>
    );
}